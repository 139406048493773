<template>
  <DropdownList
    :elements="countriesToLoop"
    :filter-placeholder="$t('components.main_dashboard.dashboard_filters.filter_countries_placeholder')"
    :selected-elements="selectedCountries"
    :handler="toggleDashboardActiveCountries"
    has-checkboxes
    filter-by="name"
    label-key="name"
    value-key="iso_alpha2"
    is-active-prop="iso_alpha2"
    menu-classes="is-medium has-max-height"
    class="DashboardFiltersCountryPicker"
  >
    <template #trigger>
      <DashboardFiltersIcon
        :label="$t('general.country')"
        class="DashboardFiltersV2-DropdownTrigger"
        icon="globe"
      />
    </template>
  </DropdownList>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import DashboardFiltersIcon from '@hypefactors/shared/js/components/filters/DashboardFiltersIcon'
import DropdownList from '@hypefactors/shared/js/components/core/DropdownList'

export default {
  name: 'DashboardFiltersCountryPicker',
  components: {
    DashboardFiltersIcon,
    DropdownList
  },
  props: {
    countries: {
      type: Array,
      default: null
    }
  },
  computed: {
    ...mapGetters({ countriesInStore: 'countries' }),
    ...mapGetters('globalFilters', [
      'selectedCountries'
    ]),
    countriesToLoop () {
      return this.countries || this.countriesInStore
    }
  },
  methods: {
    ...mapActions('globalFilters', [
      'toggleDashboardActiveCountries'
    ])
  }
}
</script>
