export const TIER_TYPES = {
  aplus: {
    label: 'components.tiers.aplus',
    value: 'aplus'
  },
  bplus: {
    label: 'components.tiers.bplus',
    value: 'bplus'
  },
  'no-tier': {
    label: 'components.tiers.no-tier',
    value: 'no-tier'
  }
}

export const TIER_TYPES_ARRAY = Object.entries(TIER_TYPES).map(([key, tier]) => ({ key, ...tier }))
