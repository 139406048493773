<template>
  <dashboard-filters-country-picker :countries="clippingMarkets" />
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import DashboardFiltersCountryPicker from './DashboardFiltersCountryPicker'
import { intersectBetweenArrays } from '@hypefactors/shared/js/utils/arrayUtils'
import { generateUniqueWatcher } from '@hypefactors/shared/js/utils/componentUtilities'
import * as MetricsService from '@hypefactors/shared/js/services/api/MetricsService'
import { debounceMixin } from '@hypefactors/shared/js/mixins/debounceMixin'

/**
 * @module DashboardFiltersClippingsCountryPicker
 */
export default {
  name: 'DashboardFiltersClippingsCountryPicker',
  components: { DashboardFiltersCountryPicker },
  mixins: [debounceMixin(['fetchFilterSensitiveDataDebounced'])],
  props: {
    dataSensitiveAction: {
      type: Function,
      default: () => Promise.resolve
    },
    fetchOnMounted: {
      type: Boolean,
      default: false
    },
    brands: {
      type: Array,
      required: true
    }
  },

  data () {
    return {
      clippingMarkets: []
    }
  },

  computed: {
    ...mapGetters('globalFilters', ['selectedCountries']),
    ...mapGetters(['getFilter', 'filtersQueryFor']),

    startDate () {
      return this.getFilter('start')
    },

    endDate () {
      return this.getFilter('end')
    },
    selectedDateRange () {
      return { start: this.startDate, end: this.endDate }
    }
  },

  watch: {
    brands: generateUniqueWatcher('setFilterSensitiveDataAsPendingImmediately'),
    selectedDateRange: generateUniqueWatcher('setFilterSensitiveDataAsPendingImmediately')
  },

  mounted () {
    this.fetchOnMounted && this.setFilterSensitiveDataAsPendingImmediately()
  },

  methods: {
    ...mapActions(['setFilter']),

    /**
     * Fetches the countries that are attached to clippings in the current date range
     * @returns {Promise<*>}
     */
    async fetchCountriesWithClippings () {
      try {
        const response = await MetricsService.markets({ params: { brands: this.brands, ...this.selectedDateRange } })
        this.clippingMarkets = response.data.data
        // TODO: Hack so the Report generator has access to this information...
        this.$store.commit('coverage/SET_MARKETS', this.clippingMarkets)
        await this.updateDashboardCountriesFilter()
      } catch (err) {
        this.$displayRequestError(err)
      }
    },

    /**
     * Updates the selected countries in Vuex
     * Only leaves countries that are present in the current clippingMarkets
     * @returns {Promise}
     */
    updateDashboardCountriesFilter () {
      if (!this.selectedCountries.length) return Promise.resolve()
      const countriesWithClippingsIsoOnly = this.clippingMarkets.map(c => c.iso_alpha2)
      const intersection = intersectBetweenArrays(this.selectedCountries, countriesWithClippingsIsoOnly)
      return this.setFilter({
        name: 'country',
        value: intersection
      })
    },

    setFilterSensitiveDataAsPendingImmediately () {
      // immediately note that we are not in a ready state now
      this.$emit('ready', false)
      // start polling to fetch sensitive data,
      // It is debounced, so we can call it a few times
      this.fetchFilterSensitiveDataDebounced()
    },

    /**
     * Fetches the filter sensitive data
     * @returns {Promise<void>}
     */
    async fetchFilterSensitiveDataDebounced () {
      await Promise.all([
        this.dataSensitiveAction(),
        this.fetchCountriesWithClippings()
      ])
      // everything is fetched and we are ready
      this.$emit('ready', true)
    }
  }
}
</script>
