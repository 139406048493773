<template>
  <DashboardFiltersV2 class="BaseCoverageFilters">
    <template #primary>
      <slot name="primary" />
    </template>
    <template #secondary>
      <slot name="secondary">
        <DashboardFiltersDateRangePicker :editable-date-range-input="editableDateRangeInput" />

        <VIcon type="caret-down" :even="false" />
      </slot>
    </template>
    <template #tertiary>
      <DashboardFiltersV2Item>
        <DropdownList
          key="tier"
          :elements="clippingTiers"
          :selected-elements.sync="selectedTiers"
          has-checkboxes
          key-by="value"
        >
          <template #trigger>
            <DashboardFiltersIcon
              :label="$t('general.tier')"
              class="DashboardFiltersV2-DropdownTrigger"
              icon="tier"
            />
          </template>
          <template #default="{ element }">
            {{ $t(element.label) }}
          </template>
        </DropdownList>
      </DashboardFiltersV2Item>
      <DashboardFiltersV2Item>
        <DropdownList
          key="media_type"
          :elements="mediaTypes"
          :selected-elements.sync="selectedMediaTypes"
          has-checkboxes
          key-by="key"
          data-testid="mediaTypePicker"
        >
          <template #trigger>
            <DashboardFiltersIcon
              :label="$t('general.media')"
              class="DashboardFiltersV2-DropdownTrigger"
              icon="tv"
            />
          </template>
          <template #default="{ element }">
            {{ $t(element.label) }}
          </template>
        </DropdownList>
      </DashboardFiltersV2Item>
      <DashboardFiltersV2Item>
        <DropdownList
          key="sentiment"
          :elements="sentimentTypes"
          :selected-elements.sync="selectedSentimentTypes"
          has-checkboxes
          key-by="key"
        >
          <template #trigger>
            <DashboardFiltersIcon
              :label="$t('general.sentiment')"
              class="DashboardFiltersV2-DropdownTrigger"
              icon="smiley-positive-full"
            />
          </template>
          <template #default="{ element }">
            {{ $t(element.label) }}
          </template>
        </DropdownList>
      </DashboardFiltersV2Item>
      <DashboardFiltersV2Item>
        <DropdownList
          key="tags"
          :elements="clippingsTags"
          :selected-elements.sync="selectedTags"
          :filter-placeholder="$t('components.main_dashboard.dashboard_filters.filter_by_tags_placeholder')"
          has-checkboxes
          menu-classes="is-medium has-max-height"
          filter-by="title"
          label-key="title"
        >
          <template #trigger>
            <DashboardFiltersIcon
              :label="$t('general.tags')"
              class="DashboardFiltersV2-DropdownTrigger"
              icon="tag"
            />
          </template>
        </DropdownList>
      </DashboardFiltersV2Item>
      <DashboardFiltersV2Item>
        <dashboard-filters-clippings-country-picker
          ref="countryPicker"
          :brands="brands"
          :data-sensitive-action="fetchClippingsTags"
          fetch-on-mounted
          @ready="$emit('ready', $event)"
        />
      </DashboardFiltersV2Item>
      <slot name="tertiary" />
    </template>
  </DashboardFiltersV2>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import _intersectionBy from 'lodash/intersectionBy'

import * as MetricsService from '@hypefactors/shared/js/services/api/MetricsService'

import DashboardFiltersIcon from '@hypefactors/shared/js/components/filters/DashboardFiltersIcon'
import DashboardFiltersV2 from '@hypefactors/shared/js/components/filters/DashboardFiltersV2'
import DashboardFiltersV2Item from '@hypefactors/shared/js/components/filters/DashboardFiltersV2Item'
import DashboardFiltersClippingsCountryPicker from '@hypefactors/shared/js/components/filters/DashboardFiltersClippingsCountryPicker'
import DashboardFiltersDateRangePicker from '@hypefactors/shared/js/components/filters/DashboardFiltersDateRangePicker'
import DropdownList from '@hypefactors/shared/js/components/core/DropdownList'
import VIcon from '@hypefactors/shared/js/components/core/VIcon'

import { TIER_TYPES_ARRAY } from '@hypefactors/shared/js/constants/tierTypes'
import { SENTIMENT_TYPES_ARRAY } from '@hypefactors/shared/js/constants/sentimentTypes'
import { MEDIA_TYPES_ARRAY, UNMEASURABLE_MEDIA_TYPES } from '@hypefactors/shared/js/constants/mediaTypes'

/**
 * Serves as a base for all Coverage related filters
 * @module BaseCoverageFilters
 */
export default {
  name: 'BaseCoverageFilters',

  components: {
    DashboardFiltersIcon,
    DashboardFiltersV2,
    DashboardFiltersV2Item,
    DashboardFiltersDateRangePicker,
    DashboardFiltersClippingsCountryPicker,
    DropdownList,
    VIcon
  },

  props: {
    editableDateRangeInput: {
      type: Boolean,
      default: true
    }
  },

  data () {
    return {
      clippingTiers: TIER_TYPES_ARRAY,
      clippingsTags: [],
      isGeneratingReport: false,
      mediaTypes: MEDIA_TYPES_ARRAY.filter(m => !UNMEASURABLE_MEDIA_TYPES.includes(m.key)),
      sentimentTypes: SENTIMENT_TYPES_ARRAY
    }
  },

  computed: {
    ...mapGetters(['getFilter']),
    ...mapGetters('coverage', ['appliedCoverageFilters']),

    startDate () {
      return this.getFilter('start')
    },

    endDate () {
      return this.getFilter('end')
    },

    selectedDateRange () {
      return { start: this.startDate, end: this.endDate }
    },

    brands () {
      return this.appliedCoverageFilters.brands || []
    },

    selectedTiers: {
      get () {
        const tiers = this.getFilter('tier') || []
        return tiers.map(t => this.clippingTiers.find(clippingTier => clippingTier.value === t))
      },
      set (value) {
        this.setFilter({
          name: 'tier',
          value: value.map(tier => tier.value)
        })
      }
    },
    selectedTags: {
      get () {
        const tags = this.getFilter('tag') || []
        return tags.map(t => this.clippingsTags.find(clippingTag => clippingTag.title === t))
      },
      set (value) {
        this.setFilter({
          name: 'tag',
          value: value.map(tag => tag.title)
        })
      }
    },
    selectedMediaTypes: {
      get () {
        const mediaTypes = this.getFilter('type') || []
        return mediaTypes.map(t => this.mediaTypes.find(mediaType => mediaType.key === t))
      },
      set (value) {
        this.setFilter({
          name: 'type',
          value: value.map(media => media.key)
        })
      }
    },
    selectedSentimentTypes: {
      get () {
        const sentiments = this.getFilter('sentiment') || []
        return sentiments.map(s => this.sentimentTypes.find(sentiment => sentiment.key === s))
      },
      set (value) {
        this.setFilter({
          name: 'sentiment',
          value: value.map(sentiment => sentiment.key)
        })
      }
    }
  },

  methods: {
    ...mapActions(['setFilter']),
    /**
     * Fetches the tags that are attached to clippings in the current date range
     * @returns {Promise<any>}
     */
    fetchClippingsTags () {
      return MetricsService.tags({ params: { brands: this.brands, ...this.selectedDateRange } })
        .then(response => {
          this.clippingsTags = response.data.data
          // TODO: Hack so the Report generator has access to this information...
          this.$store.commit('coverage/SET_TAGS', this.clippingsTags)
          if (!this.selectedTags.length) return

          this.selectedTags = _intersectionBy(this.selectedTags, this.clippingsTags, 'id')
        })
        .catch(err => {
          this.$displayRequestError(err)
        })
    }
  }
}
</script>
