<template>
  <PageSection
    class="ExportOrganisationClippings"
    :page-title="$t('pages.settings.company.export-organisation-clippings.title')"
    :page-tooltip="$t('pages.settings.company.export-organisation-clippings.tooltip')"
  >
    <BaseCoverageFilters class="CoverageReportGenerator">
      <template #tertiary>
        <DashboardFiltersV2>
          <VButton
            :loading="isExportingClippings"
            class="button is-black is-narrow"
            @click="exportClippings"
          >
            {{ $t('components.reports.generate_report.export_clippings') }}
          </VButton>
        </DashboardFiltersV2>
      </template>
    </BaseCoverageFilters>
  </PageSection>
</template>

<script>
import { mapGetters } from 'vuex'

import VButton from '@hypefactors/shared/js/components/core/VButton.vue'
import BaseCoverageFilters from '@hypefactors/shared/js/components/clippings/BaseCoverageFilters.vue'
import DashboardFiltersV2 from '@hypefactors/shared/js/components/filters/DashboardFiltersV2.vue'

import store from '@/store'
import { ClippingsApi } from '@/services/api/ClippingsApiService.js'
import PageSection from '@/components/core/PageSection.vue'

export default {
  components: {
    PageSection,
    BaseCoverageFilters,
    DashboardFiltersV2,
    VButton
  },

  data () {
    return {
      isExportingClippings: false
    }
  },

  async beforeRouteEnter (to, from, next) {
    await store.dispatch('globalFilters/setDefaultFiltersTimeFiltersIfNeeded')

    next()
  },

  computed: {
    ...mapGetters('coverage', ['appliedCoverageFilters']),

    ...mapGetters(['brands'])
  },

  methods: {
    async exportClippings () {
      this.isExportingClippings = true

      try {
        await ClippingsApi.exportClippings({
          ...this.appliedCoverageFilters,
          brands: this.brands.map(b => b.id)
        })

        this.$notify.success(this.$t('components.reports.generate_report.export_success'))
      } catch (error) {
        this.$displayRequestError(error, this.$t('components.reports.generate_report.export_error'))
      } finally {
        this.isExportingClippings = false
      }
    }
  }
}
</script>
